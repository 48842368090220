import * as React from 'react'

import IndexPage from 'pages/palette/index'

import { Locale } from '../../utils/enums'

const IndexPageDe = (): JSX.Element => {
    return <IndexPage locale={Locale.de_DE} />
}

export default IndexPageDe
